<template lang="pug">
div
  Form(:data="document", :isSubmitting="isSubmitting", @submit="create")
</template>

<script>
import Form from '../shared/Form.vue'
import useDocumentAdd from './useDocumentAdd'

export default {
  components: {
    Form,
  },
  setup() {
    const { create, document, isSubmitting } = useDocumentAdd()

    return {
      document,
      create,
      isSubmitting,
    }
  },
}
</script>
