import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws'
import realmConnection from '@/views/habit/realm'

export default function useDocumentAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItem, ObjectId } = realmConnection()
  const { singleUpload } = awsConnection()
  const userData = store.state?.userStore?.userData
  const document = ref({})
  const isSubmitting = ref(false)
  const collection = 'document'

  const create = async (data, documentFile) => {
    isSubmitting.value = true

    const payload = {
      client_id: ObjectId(userData.client.$oid),
      name: data.name?.value || ''
    }

    try {
      const item = await createItem({ collection, payload })
      if (!item) throw new Error('Item not created')
      
      // Upload file to AWS and then update the document in MongoDB with the AWS file key
      if (documentFile.name) {
        const documentId = item.insertedId
        const destinationFolder = `${userData.client.$oid}/documents`
        
        const key = await singleUpload(documentFile, destinationFolder)
        
        updateDocumentWithKey(documentId, key)
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.document_add_error'))
    } finally {
      router.push({ name: 'organization-document-list' })
      isSubmitting.value = false
    }
  }

  const updateDocumentWithKey = async (documentId, key) => {
    try {
      const query = { _id: documentId }
      const action = { $set: { key } }

      await updateItem({ collection, query, action })
      showSuccessMessage(i18n.t('message.document_added'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.document_add_error'))
    }
  }


  return {
    create,
    document,
    isSubmitting
  }
}
